import { v4 as uuidv4 } from 'uuid'
import { buildUserLocation } from './buildUserLocation'
import { hashData } from '@/fractals/utils'
import { getTextByPromoCodeFortuneWheel } from '@/fractals/utils/getTextByPromoCodeFortuneWheel'

type TypeReferer = string | null

export type TypeUtms = {
  readonly utm_source?: string
  readonly utm_medium?: string
  readonly utm_campaign?: string
  readonly utm_content?: string
  readonly utm_term?: string
  readonly cl_uid?: string
}

type TypeFormAlphaValues = {
  readonly name?: string
  readonly phoneNumber: string
  readonly email?: string
  readonly promocode?: string
}

type TypeLeadClientValues = Awaited<TypeFormAlphaValues> & {
  readonly id: string
  readonly programTitle?: string
  readonly leadPage: string
  readonly currentFacultySlug: string
  readonly currentProgramSlug: string
  readonly utms: TypeUtms
  readonly price: number
  readonly referer: TypeReferer
}

type TypeBuildLeadDataProps = Awaited<TypeLeadClientValues> & {
  rootPath: string
  ip: string
  gclUid: string
  ymclUid: string
  ymUid: string
  ymCounter: string
  clUid: string
  utmCampaign: string
  utmMedium: string
  utmSource: string
  location: Awaited<Promise<ReturnType<typeof buildUserLocation>>>
  question?: string
  contactWay?: string
  contactMethod?: string
  subscribe?: string
}

export const buildLeadData = async ({
  name,
  phoneNumber,
  email,
  id,
  subscribe,
  programTitle,
  leadPage,
  currentFacultySlug,
  currentProgramSlug,
  utms,
  referer,
  rootPath,
  ip,
  location,
  gclUid,
  ymclUid,
  clUid,
  utmCampaign,
  utmMedium,
  utmSource,
  promocode,
  price,
  question,
  contactWay,
  contactMethod,
  ymUid,
  ymCounter
}: TypeBuildLeadDataProps) => {
  const now = new Date()
  const date = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`
  const time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
  const utc = `GMT${now.toString().split('GMT')[1]}`

  const output = {
    id: id || (await hashData(`${phoneNumber}-${email}`)),
    date,
    time,
    utc,
    name: name || null,
    phone: phoneNumber || null,
    email: email || null,
    vk: null,
    subscribe: subscribe ?? null,
    promocode: getTextByPromoCodeFortuneWheel(promocode) || promocode || null,
    contactWay: contactWay ?? null,
    contactMethod: contactMethod ?? null,
    question: question ?? null,
    rootPath: rootPath || null,
    leadPage: leadPage || null,
    ip: location?.data.ip || null,
    // cityEn: location?.city.names.en || null,
    cityEn: location?.data?.city || null,
    // cityRu: location?.city.names.ru || null,
    cityRu: null,
    // countryCode: location?.country.code || null,
    countryCode: location?.data?.country_code || null,
    // countryEn: location?.country.names.en || null,
    countryEn: location?.data?.country_name || null,
    // countryRu: location?.country.names.ru || null,
    countryRu: null,
    // continentCode: location?.continent.code || null,
    continentCode: location?.data?.continent_code || null,
    // continentEn: location?.continent.names.en || null,
    continentEn: null,
    // continentRu: location?.continent.names.ru || null,
    continentRu: null,
    // accuracyRadius: location?.coordinates.accuracyRadius || null,
    countryArea: location?.data?.country_area || null,
    countryPopulation: location?.data?.country_population || null,
    currencyName: location?.data?.currency_name || null,
    // latitude: location?.coordinates.latitude || null,
    latitude: location?.data?.latitude || null,
    // longitude: location?.coordinates.longitude || null,
    longitude: location?.data?.longitude || null,
    // timeZone: location?.timeZone || null,
    timeZone: location?.data?.timezone || null,
    // postalCode: location?.postalCode || null,
    postalCode: location?.data?.postal || null,
    programTitle:
      (currentProgramSlug && currentProgramSlug?.toString()) || null,
    price: price || null,
    utmSource: (utms && utms.utm_source) || utmSource || referer || null,
    utmMedium: (utms && utms.utm_medium) || utmMedium || null,
    utmCampaign: (utms && utms.utm_campaign) || utmCampaign || null,
    utmContent: (utms && utms.utm_content) || null,
    utmTerm: (utms && utms.utm_term) || null,
    clUid: (utms && utms.cl_uid) || clUid || null,
    gclUid: (gclUid && gclUid) || null,
    ymclUid: (ymclUid && ymclUid) || null,
    _ym_uid: ymUid || null,
    _ym_counter: ymCounter
  }

  return output
}

export default buildLeadData
