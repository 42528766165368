import { ENV } from './env' // * import from 'fractals' won't work

const LOCALHOST = 'localhost'

const PROTOCOLS = {
  delimiter: '://',
  http: 'http',
  https: 'https'
} as const

const PORTS = {
  delimiter: ':',
  front: {
    default: {
      dev: 3000
    }
  },
  back: {
    default: {
      dev: 1337
    }
  }
} as const

const DOMAINS = {
  front: {
    default: {
      dev: LOCALHOST,
      prod: 'mitm.institute'
    }
  },
  back: {
    default: {
      dev: LOCALHOST,
      prod: 'api.mitm.institute',
      prodDev: 'dev.api.mitm.institute'
    }
  }
} as const

// TODO: figure out better way to handle root index values
const ROUTES_FRONT = {
  root: {
    index: ENV.dev
      ? (`${PROTOCOLS.http}${PROTOCOLS.delimiter}${DOMAINS.front.default.dev}${PORTS.delimiter}${PORTS.front.default.dev}` as const)
      : (`${PROTOCOLS.https}${PROTOCOLS.delimiter}${DOMAINS.front.default.prod}` as const)
  },
  home: {
    index: '/'
  },
  api: {
    index: '/api',
    lead: {
      index: '/api/lead'
    },
    leadmagnet: {
      index: '/api/leadmagnet'
    },
    webhook: {
      index: '/api/webhook'
    }
  },
  contact: {
    index: '/contact'
  },
  journal: {
    ru: '/journal',
    en: '/journal',
    uz: '/jurnal',
    kk: '/jwrnal'
  },
  general: {
    programs: {
      ru: '/programmy',
      en: '/programs',
      uz: '/programlar',
      kk: '/programlar'
    },
    faculty: {
      ru: 'fakultet',
      en: 'faculty',
      uz: 'fakultet',
      kk: 'fakultetler'
    },
    program: {
      index: '/:program__category'
    }
  },
  public: {
    faviconIco: {
      index: '/favicon.ico'
    },
    faviconSvg: {
      index: '/favicon.svg'
    },
    policy: {
      index: '/policies/privacy.pdf'
    },
    tems: {
      index: '/policies/terms.pdf'
    },
    assets: {
      imgs: {
        icons: {
          mitmLogo180: {
            index: '/assets/imgs/icons/mitm-logo-180-180.png'
          },
          mitmLogo192: {
            index: '/assets/imgs/icons/mitm-logo-192-192.png'
          },
          mitmLogo512: {
            index: '/assets/imgs/icons/mitm-logo-512-512.png'
          }
        },
        general: {
          lady: {
            index: '/assets/imgs/general/lady.jpg'
          }
        },
        diplomas: {
          diploma: {
            index: '/assets/imgs/general/diploma.jpg'
          }
        },
        teachers: {
          ivanIvanov: {
            index: '/assets/imgs/teachers/ivan-ivanov.jpg'
          }
        }
      }
    }
  }
} as const

const ROUTES_BACK = {
  root: {
    index: ENV.dev
      ? (`${PROTOCOLS.http}${PROTOCOLS.delimiter}${DOMAINS.back.default.dev}${PORTS.delimiter}${PORTS.back.default.dev}` as const)
      : // (`${PROTOCOLS.https}${PROTOCOLS.delimiter}${DOMAINS.back.default.prodDev}` as const)
        (`${PROTOCOLS.https}${PROTOCOLS.delimiter}${DOMAINS.back.default.prod}` as const)
  },
  home: {
    index: '/'
  },
  admin: {
    index: '/admin'
  },
  api: {
    index: '/api',
    categories: {
      index: '/api/categories'
    },
    faculties: {
      index: '/api/faculties'
    },
    banner: {
      index: '/api/banner'
    },
    tags: {
      index: '/api/tags'
    },
    programs: {
      index: '/api/programs'
    },
    diplomas: {
      index: '/api/diplomas'
    },
    questions: {
      index: '/api/questions'
    },
    reviews: {
      index: '/api/reviews'
    },
    teachers: {
      index: '/api/teachers'
    },
    journals: {
      index: '/api/journals'
    },
    stories: {
      index: '/api/stories'
    },
    missions: {
      index: '/api/missions'
    },
    whyUses: {
      index: '/api/why-uses'
    },
    videos: {
      index: '/api/videos'
    }
  }
} as const

export const PATHS = {
  front: ROUTES_FRONT,
  back: ROUTES_BACK
}
