import {
  Form,
  Input,
  Label,
  Div,
  toUnit,
  Button,
  useTranslations,
  Span,
  Image,
  P,
  PATHS,
  useMedia,
  useAt,
  media,
  FormBalls,
  multiMedia,
  getCountryCodeByPhonePrefix,
  getPhonePrefixByCountryCode,
  phonePrefixToCountryCode,
  Select
} from 'fractals'
import * as flag from 'country-flag-icons/string/3x2'
import { v4 as uuidv4 } from 'uuid'
import { getCookies } from 'cookies-next'
import { insertWhiteSpacesAfterPrepositions, Layout, mdToJsx } from 'ui'
import { useEffect, useRef, useState } from 'react'
import Color from 'color'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { useRouter } from 'next/router'
import { TLeadFormProps } from './types'
import { selectStyles } from './SelectStyles'
import {
  STYLE_VARIANT_KEYS,
  COLORS_VARIANT_KEYS,
  DEFAULT_BUTTON_VALUE,
  DEFAULT_FORM_HEADING,
  POLICY_AGREEMENT,
  IMAGE_ALT,
  IS_LOADING,
  IS_SENT,
  IS_ERORR,
  NAME_MAX_LENTH,
  NAME_MAX_LENGTH_VALUE,
  NAME_PATTERN,
  PHONE_NUMBER_IS_REQUIRED,
  PHONE_NUMBER_MIN_LENGTH,
  PHONE_NUMBER_MIN_LENGTH_VALUE,
  EMAIL_PATTERN,
  EMAIL_MAX_LENGTH,
  EMAIL_MAX_LENGTH_VALUE,
  NAME_IS_REQUIRED,
  PROMOCODE_MAX_LENGTH,
  PROMOCODE_MAX_LENGTH_VALUE,
  PHONE_NUMBER_PATTERN
} from './constants'
import image from './online-student.png'
import checkIsLeadFromSravni from '@/fractals/utils/checkIsLeadFromSravni/checkIsLeadFromSravni'
import { FlagItem } from './fractals'
import { useYandexEcommerceData } from '../../contexts/YandexEcommerceContext'
import useYandexEcommerce from '@/fractals/hooks/useYandexEcommerce'

// TODO: refactor this
type TLeadFormValues = {
  name?: string
  phoneNumber: string
  email?: string
  promocode?: string
  pdfData?: {
    name: string
    url: string
  }[]
  price?: number | null
}

type TOnSubmitFormProps = TLeadFormValues

declare global {
  interface Window {
    dataLayer?: any[]
  }
}

type TypeLeadProps = {
  // id: string
  event?: 'lead' | 'getmail'
  program: {
    id: string
    title: string
    price: number
    category: string
    studyField: string
  } | null
  googleClientId: string | null
  email?: string | null
}

export const margetingLead = ({
  event = 'lead',
  program,
  googleClientId,
  email
}: TypeLeadProps) => {
  if (typeof window !== undefined)
    window?.dataLayer?.push({
      event,
      ecommerce: {
        add: {
          actionField: {
            googleClientId
          },
          products: [
            {
              id: program?.id,
              name: program?.title,
              price: program?.price,
              category: program?.category,
              studyField: program?.studyField
            }
          ],
          email
        }
      }
    })
}

export const LeadForm = ({
  formName,
  styleVariant,
  name,
  phoneNumber,
  email,
  promocode,
  title,
  buttonValue,
  heading,
  colorVariant,
  colorsVariant,
  withImage,
  withJournalImage,
  isSandwich,
  isLabel,
  isPopup,
  isPopupJournal,
  isJournal,
  cost,
  program,
  pdfData,
  onSuccess,
  isPsychology,
  temporalFortuneWheel = null,
  id,
  ...props
}: TLeadFormProps) => {
  const [
    defaultButtonValue,
    defaultFormHeading,
    policyAgreement,
    imageAlt,
    isLoadingText,
    isSentText,
    isErrorText,
    nameIsRequired,
    nameMaxLength,
    namePattern,
    phoneNumberIsRequired,
    phoneNumberMinLength,
    phoneNumberPattern,
    emailPattern,
    emailMaxLength,
    promocodeMaxLength
  ] = useTranslations(
    DEFAULT_BUTTON_VALUE,
    DEFAULT_FORM_HEADING,
    POLICY_AGREEMENT,
    IMAGE_ALT,
    IS_LOADING,
    IS_SENT,
    IS_ERORR,
    NAME_IS_REQUIRED,
    NAME_MAX_LENTH,
    NAME_PATTERN,
    PHONE_NUMBER_IS_REQUIRED,
    PHONE_NUMBER_MIN_LENGTH,
    PHONE_NUMBER_PATTERN,
    EMAIL_PATTERN,
    EMAIL_MAX_LENGTH,
    PROMOCODE_MAX_LENGTH
  )

  const { isProgramPage, isJournalPage } = useAt()

  const { isDesktop, isLaptop, isTablet, isMobile } = useMedia()

  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [countryCode, setCountryCode] = useState('RU')
  const [isOpen, setIsOpen] = useState(false)
  const [contentPhonePrefixToCountryCode, setContentPhonePrefixToCountryCode] =
    useState<{
      [prefix: string]: { code: string; ru: string; native: string }
    }>()

  useEffect(() => {
    setContentPhonePrefixToCountryCode(phonePrefixToCountryCode)
  }, [])

  const {
    control,
    watch,
    handleSubmit,
    reset,
    getValues,
    setFocus,
    formState: { errors }
  } = useForm<TLeadFormValues>({
    defaultValues: {
      name: '',
      phoneNumber: '+7',
      email: '',
      promocode: temporalFortuneWheel || ''
    }
  })

  const watchedPhoneNumber = watch('phoneNumber')
  const watchedName = watch('name')
  const watchedEmail = watch('email')
  const previousPhoneNumber = useRef<string | undefined>()

  const { locale } = useRouter()

  const { sendPurchaseEvent } = useYandexEcommerce()

  const { YandexEcommerceProduct } = useYandexEcommerceData()

  useEffect(() => {
    if (watchedPhoneNumber !== previousPhoneNumber.current) {
      setCountryCode(getCountryCodeByPhonePrefix(watchedPhoneNumber).code)
      previousPhoneNumber.current = watchedPhoneNumber
      reset({
        ...getValues(),
        phoneNumber: watchedPhoneNumber
      })
    }
  }, [reset, watchedPhoneNumber, getValues])

  const ids = {
    name: `LeadForm--id__input-type--name__${id}`,
    phoneNumber: `LeadForm--id__input-type--phoneNumber__${id}`,
    email: `LeadForm--id__input-type--email__${id}`,
    promocode: `LeadForm--id__input-type--promocode__${id}`
  } as const

  const inputs = [
    {
      isOn: name,
      id: ids.name,
      type: 'name',
      label: {
        htmlFor: ids.name,
        childrenVariant: 'name'
      },
      placeholder: 'name',
      rules: {
        maxLength: {
          value: NAME_MAX_LENGTH_VALUE,
          message: nameMaxLength
        },
        ...(isJournalPage && { required: nameIsRequired }),
        ...(locale === 'ru' && {
          pattern: {
            value: /^[а-яА-ЯёЁ\s]+$/i,
            message: namePattern
          }
        })
      }
    },
    {
      isOn: phoneNumber,
      id: ids.phoneNumber,
      type: 'phoneNumber',
      label: {
        htmlFor: ids.phoneNumber,
        // htmlFor: 'phoneNumber',
        childrenVariant: 'phoneNumber'
      },
      placeholder: 'phoneNumber',
      ...(!isJournalPage && {
        rules: {
          required: phoneNumberIsRequired,
          minLength: {
            value: PHONE_NUMBER_MIN_LENGTH_VALUE,
            message: phoneNumberMinLength
          },
          pattern: {
            value:
              /^\+?[0-9]{1,3}?[-\s.]?[0-9]{1,3}?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2,6}$/,
            message: phoneNumberPattern
          }
        }
      })
    },
    {
      isOn: email,
      id: ids.email,
      type: 'email',
      label: {
        htmlFor: ids.email,
        // htmlFor: 'email',
        childrenVariant: 'email'
      },
      placeholder: 'email',
      rules: {
        pattern: {
          value:
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          message: emailPattern
        },
        maxLength: {
          value: EMAIL_MAX_LENGTH_VALUE,
          message: emailMaxLength
        }
      }
    },
    {
      isOn: promocode,
      id: ids.promocode,
      type: 'promocode',
      label: {
        htmlFor: ids.promocode,
        // htmlFor: 'email',
        childrenVariant: 'promocode'
      },
      placeholder: 'promocode',
      rules: {
        maxLength: {
          value: PROMOCODE_MAX_LENGTH_VALUE,
          message: promocodeMaxLength
        }
      }
    }
  ] as const

  const colorsVariants = {
    [COLORS_VARIANT_KEYS.alpha]: {
      container: {
        styles: {
          backgroundColor: 'var(--chi)',
          color: 'var(--omega)'
        }
      },
      button: {
        colorsVariant: 'beta'
      },
      input: {
        backgroundColor: 'var(--alpha)'
      }
    },
    [COLORS_VARIANT_KEYS.beta]: {
      container: {
        styles: {
          backgroundColor: 'var(--alpha)',
          color: 'var(--omega)'
        }
      },
      button: {
        colorsVariant: 'omega'
      },
      input: {
        backgroundColor: 'var(--chi)'
      }
    },
    [COLORS_VARIANT_KEYS.gamma]: {
      container: {
        styles: {
          // backgroundColor: 'var(--alpha)',
          color: 'var(--alpha)'
        }
      },
      button: {
        colorsVariant: 'omega'
      },
      input: {
        backgroundColor: 'var(--alpha)'
      }
    },
    [COLORS_VARIANT_KEYS.delta]: {
      container: {
        styles: {
          backgroundColor: 'var(--chi)',
          color: 'var(--omega)'
        }
      },
      button: {
        colorsVariant: 'delta'
      },
      input: {
        backgroundColor: 'var(--alpha)'
      }
    }
  } as const

  const styleVariants = {
    [STYLE_VARIANT_KEYS.alpha]: {
      container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingTop: toUnit(32),
        paddingRight: toUnit(32),
        paddingBottom: toUnit(32),
        paddingLeft: toUnit(32),
        borderRadius: toUnit(16)
      },
      heading: {
        maxWidth: (isLaptop && '50ch') || undefined,
        textAlign: (isDesktop && 'center') || undefined,
        color: 'var(--omega)',
        '& strong': {
          fontWeight: 'inherit',
          color:
            (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
            'var(--omega)'
        },
        ...media('marginBottom', toUnit(24), {
          tablet: toUnit(32),
          laptop: toUnit(32),
          desktop: toUnit(32)
        }),
        fontSize: toUnit(20),
        fontWeight: 500,
        lineHeight: '120%',
        display: 'block'
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      },
      inputs: {
        //
      },
      inputGroup: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      },
      label: {
        marginBottom: toUnit(16),
        fontSize: toUnit(14),
        fontWeight: 400,
        lineHeight: '120%',
        color: 'var(--omega)'
      },
      input: {
        display: 'block',
        flex: 1,
        fontSize: toUnit(16),
        fontWeight: 400,
        lineHeight: '120%',
        marginTop: 'auto',
        paddingTop: toUnit(16),
        paddingRight: toUnit(16),
        paddingBottom: toUnit(16),
        paddingLeft: toUnit(16),
        borderRadius: toUnit(8),
        color: 'var(--omega)',
        '&::placeholder': {
          color: 'var(--phi)'
        }
      },
      p: {
        //
      },
      buttonContainer: {
        display: 'flex',
        flexBasis: (isLaptop && '100%') || undefined,
        flexDirection: 'column'
      },
      button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        marginTop: 'auto'
      },
      ballsContainer: {
        position: 'absolute',
        ...multiMedia({
          display: 'none',
          tablet: {
            display: 'flex'
          }
        }),
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '& span': {
          position: 'absolute',
          ':nth-child(1)': {
            top: 0,
            left: 0,
            marginTop: '244px',
            transform: 'translateX(-7px)'
          },
          ':nth-child(2)': {
            top: 0,
            right: 0,
            marginTop: '61px',
            transform: 'translateX(50%)'
          },
          ':nth-child(3)': {
            bottom: 0,
            right: 0,
            marginBottom: '94px',
            transform: 'translateX(7px)'
          },
          ':nth-child(4)': {
            bottom: 0,
            left: 0,
            marginBottom: '37.4px',
            transform: 'translateX(-93px)'
          }
        }
      },

      policyAgreement: {
        display: 'inline-block',
        textAlign: (isDesktop && 'center') || undefined,
        marginTop: toUnit(16),
        fontSize: toUnit(12),
        fontWeight: 400,
        lineHeight: '120%',
        color: 'var(--upsilon)'
      }
    },
    [STYLE_VARIANT_KEYS.beta]: {
      container: {
        display: 'flex',
        // flexDirection: 'column',
        // flexGrow: 1,
        // paddingTop: toUnit(32),
        // paddingRight: toUnit(32),
        paddingBottom: isPopupJournal ? '' : toUnit(32),
        ...media('marginTop', isPopupJournal ? '44px' : '32px', {
          laptop: '32px',
          tablet: '24px',
          phone: '24px'
        })
      },
      heading: {
        maxWidth: (isLaptop && '50ch') || undefined,
        textAlign: (isDesktop && 'center') || undefined,
        color: 'var(--alpha)',
        '& strong': {
          fontWeight: 'inherit',
          color:
            (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
            'var(--alpha)'
        },
        ...media('marginBottom', toUnit(24), {
          tablet: toUnit(32),
          laptop: toUnit(32),
          desktop: toUnit(32)
        }),
        fontSize: toUnit(20),
        fontWeight: 500,
        lineHeight: '120%',
        display: 'block'
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: isPopupJournal ? '' : `0 ${toUnit(32)}`
      },
      inputs: {
        // padding: `0 ${toUnit(32)}`
      },
      inputGroup: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        boxSizing: 'border-box',

        minWidth:
          isTablet && isPopupJournal ? '-webkit-fill-available !important' : '',
        ...media('maxWidth', null, {
          laptop: isPopupJournal ? '27rem' : '',
          desktop: isPopupJournal ? '36rem' : '',
          tablet: isPopupJournal ? '31rem' : ''
        })
      },
      label: {
        marginBottom: toUnit(16),
        fontSize: toUnit(14),
        fontWeight: 400,
        lineHeight: '120%',
        color: 'var(--alpha)'
      },
      input: {
        ...media('width', isPopupJournal ? '424px' : '352px', {
          laptop: isPopupJournal ? '424px' : '100%',
          tablet: isPopupJournal ? '412px' : '284px',
          phone: '100%'
        }),
        display: 'block',
        fontSize: toUnit(16),
        fontWeight: 400,
        lineHeight: '120%',
        marginTop: 0,
        paddingTop: toUnit(16),
        paddingRight: toUnit(16),
        paddingBottom: toUnit(16),
        paddingLeft: toUnit(16),
        borderRadius: toUnit(8),
        color: 'var(--omega)',
        '&::placeholder': {
          color: 'var(--phi)'
        }
      },
      p: {
        position: 'absolute',
        top: '5rem'
      },
      buttonContainer: {
        display: 'flex',
        boxSizing: 'border-box',
        // flexBasis: ((isLaptop || isDesktop) && '100%') || undefined,
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '2rem'
      },
      button: {
        paddingLeft: '1rem',
        ...media('width', null, {
          laptop: isPopupJournal ? '27rem' : '',
          desktop: isPopupJournal ? '424px' : '352px'
        }),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // flexGrow: 1,
        marginTop: 0,
        marginBottom: 'auto',
        ':hover': {
          borderColor: 'var(--omega)'
        },
        ':disabled': {
          backgroundColor: '#0E0E0E !important',
          fxBackgroundColor: '#0E0E0E',
          opacity: '0.8'
        }
      },
      ballsContainer: {
        position: 'absolute',
        ...multiMedia({
          display: 'none',
          tablet: {
            display: 'flex'
          }
        }),
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '& span': {
          position: 'absolute',
          ':nth-child(1)': {
            top: 0,
            left: 0,
            marginTop: '244px',
            transform: 'translateX(-7px)'
          },
          ':nth-child(2)': {
            top: 0,
            right: 0,
            marginTop: '61px',
            transform: 'translateX(50%)'
          },
          ':nth-child(3)': {
            bottom: 0,
            right: 0,
            marginBottom: '94px',
            transform: 'translateX(7px)'
          },
          ':nth-child(4)': {
            bottom: 0,
            left: 0,
            marginBottom: '37.4px',
            transform: 'translateX(-93px)'
          }
        }
      },

      policyAgreement: {
        display: 'inline-block',
        textAlign: 'center',
        marginTop: toUnit(16),
        fontSize: toUnit(12),
        fontWeight: 400,
        lineHeight: '120%',
        color: 'var(--alpha)'
      }
    },
    [STYLE_VARIANT_KEYS.gamma]: {
      container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingTop: toUnit(32),
        paddingRight: toUnit(32),
        paddingBottom: toUnit(32),
        paddingLeft: toUnit(32),
        borderRadius: toUnit(16)
      },
      heading: {
        textAlign: (!isMobile && 'center') || undefined,
        color: 'var(--omega)',
        '& strong': {
          fontWeight: 'inherit',
          color:
            (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
            'var(--omega)'
        },
        ...media('marginBottom', toUnit(24), {
          tablet: toUnit(32),
          laptop: toUnit(32),
          desktop: toUnit(32)
        }),
        fontSize: toUnit(20),
        fontWeight: 500,
        lineHeight: '120%',
        display: 'block'
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      },
      inputs: {
        //
      },
      inputGroup: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      },
      label: {
        marginBottom: toUnit(16),
        fontSize: toUnit(14),
        fontWeight: 400,
        lineHeight: '120%',
        color: 'var(--omega)'
      },
      input: {
        display: 'block',
        flex: 1,
        fontSize: toUnit(16),
        fontWeight: 400,
        lineHeight: '120%',
        marginTop: 'auto',
        paddingTop: toUnit(16),
        paddingRight: toUnit(16),
        paddingBottom: toUnit(16),
        paddingLeft: toUnit(16),
        borderRadius: toUnit(8),
        color: 'var(--omega)',
        '&::placeholder': {
          color: 'var(--phi)'
        }
      },
      p: {
        //
      },
      buttonContainer: {
        display: 'flex',
        flexBasis: (isLaptop && '100%') || undefined,
        flexDirection: 'column',
        flexGrow: 2
      },
      button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        marginTop: 'auto'
      },
      ballsContainer: {
        position: 'absolute',
        ...multiMedia({
          display: 'none',
          tablet: {
            display: 'flex'
          }
        }),
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '& span': {
          position: 'absolute',
          ':nth-child(1)': {
            top: 0,
            left: 0,
            marginTop: '244px',
            transform: 'translateX(-7px)'
          },
          ':nth-child(2)': {
            top: 0,
            right: 0,
            marginTop: '61px',
            transform: 'translateX(50%)'
          },
          ':nth-child(3)': {
            bottom: 0,
            right: 0,
            marginBottom: '94px',
            transform: 'translateX(7px)'
          },
          ':nth-child(4)': {
            bottom: 0,
            left: 0,
            marginBottom: '37.4px',
            transform: 'translateX(-93px)'
          }
        }
      },
      policyAgreement: {
        display: 'inline-block',
        textAlign: 'center',
        marginTop: toUnit(16),
        fontSize: toUnit(12),
        fontWeight: 400,
        lineHeight: '120%',
        color: 'var(--upsilon)'
      }
    }
  } as const

  // TODO: refactor this
  const onSubmitForm = async ({ ...lead }: TOnSubmitFormProps) => {
    setIsLoading(true)
    setIsError(false)
    setIsSent(false)

    try {
      const req = await axios.post(`${PATHS.front.api.lead.index}`, {
        ...lead
      })

      if (isJournalPage) {
        const reqMagnet = await axios.post(
          `${PATHS.front.api.leadmagnet.index}`,
          {
            ...lead
          }
        )
      }

      if (req.status === 200) {
        const cookies = getCookies()
        const TRANSACTION_ID = cookies.utm_content
        const OFFER_ID = 1848
        const AMOUNT = cost
        const leadFromSravni = checkIsLeadFromSravni(getCookies())

        const sendLeadToSravni = async () => {
          const res = await axios.get(
            `https://sravni.go2cloud.org/aff_lsr?Offer_id=${OFFER_ID}&amount=${AMOUNT}&transaction_id=${TRANSACTION_ID}`
            // `http://localhost:3000/aff_lsr?offer_id=${OFFER_ID}&amount=${AMOUNT}&transaction_id=${TRANSACTION_ID}`
          )

          return res.data
        }

        if (leadFromSravni && isProgramPage) sendLeadToSravni()

        if (isProgramPage && YandexEcommerceProduct) {
          sendPurchaseEvent([YandexEcommerceProduct])
        }

        const lastUtmSource = cookies.utm_source
        const lastUtmCampaign = cookies.utm_campaign
        const lastClkUid = cookies.cl_uid
        const leadHook = {
          ...lead,
          utms: {
            utm_source: lastUtmSource,
            utm_campaign: lastUtmCampaign,
            cl_uid: lastClkUid
          }
        }

        await axios.post(`${PATHS.front.api.webhook.index}`, leadHook)

        sessionStorage.removeItem('referer')
        sessionStorage.removeItem('user_uuid')
        sessionStorage.setItem('user_uuid', JSON.stringify(uuidv4()))
        setIsLoading(false)
        setIsError(false)
        setIsSent(true)
        if (isJournalPage && onSuccess) onSuccess(true)
        margetingLead({
          program: null,
          googleClientId: cookies.gclUid || null
        })
        reset()
        return
      }
    } catch (err) {
      setIsLoading(false)
      setIsError(true)
      setIsSent(false)
      console.error(err)
    }
  }

  useEffect(() => {
    if (isPopup) setFocus('name')
  }, [isPopup, setFocus])

  // TODO: make this look better
  // TODO: refactor this
  return (
    <>
      <Div
        styles={{
          ...styleVariants[styleVariant].container,
          ...colorsVariants[colorsVariant].container.styles,
          ...props.styles
        }}>
        {(isPsychology || (!isLabel && !isJournal)) &&
          ((typeof heading === 'object' && heading) || (
            <Span styles={styleVariants[styleVariant].heading}>
              {mdToJsx(
                insertWhiteSpacesAfterPrepositions(
                  (typeof heading === 'string' && heading) || defaultFormHeading
                )
              )}
            </Span>
          ))}
        <Form
          styles={styleVariants[styleVariant].form}
          formName={formName}
          method='POST'
          onSubmit={handleSubmit(formValues => {
            onSubmitForm({
              ...formValues,
              pdfData,
              price: YandexEcommerceProduct?.price
            })
          })}>
          <Layout.Flex cols={(withImage && (isDesktop || isLaptop) && 2) || 1}>
            <Layout.FlexItem>
              <Layout.Flex
                cols={
                  (!isPopupJournal &&
                    ((isJournal && (isTablet || isDesktop) && 2) ||
                      (isJournal && (isLaptop || isMobile) && 1))) ||
                  (isPopupJournal && isJournal && 1) ||
                  (isSandwich && 1) ||
                  (isPsychology && !isMobile && 2) ||
                  (isLaptop && 3) ||
                  (isTablet && 2) ||
                  1
                }
                gap={toUnit(32)}
                styles={styleVariants[styleVariant].inputs}>
                {inputs
                  .filter(input => input.isOn)
                  .map(
                    ({ label, type, rules, id: inputId, placeholder }, idx) => (
                      <Layout.FlexItem
                        key={`LeadForm__inputs--${idx + 1}`}
                        styles={{
                          ...styleVariants[styleVariant].inputGroup
                        }}>
                        {!isLabel && (
                          <Label
                            {...label}
                            styles={styleVariants[styleVariant].label}
                          />
                        )}
                        <Controller
                          name={type}
                          control={control}
                          rules={rules}
                          render={({ field }) => (
                            <Input
                              id={inputId}
                              type={type}
                              placeholder={placeholder}
                              {...field}
                              // name={`LeadForm--${id}--input-name`}
                              // {...register(type, registerOptions)}
                              styles={{
                                ...styleVariants[styleVariant].input,
                                ...colorsVariants[colorsVariant].input
                              }}
                            />
                          )}
                        />

                        {errors && errors?.[type]?.message && (
                          <P
                            variant='kappa'
                            styles={{
                              // ...styleVariants[styleVariant].p,
                              width: 'max-content',
                              marginTop: '16px',
                              marginBottom: 0,
                              color: 'var(--zeta)',
                              fontSize: 11
                            }}>
                            {errors?.[type]?.message as string}
                          </P>
                        )}
                        {type === 'phoneNumber' && (
                          <Select
                            // ref={selectRef}
                            isOpen={isOpen}
                            onOpen={() => setIsOpen(prev => !prev)}
                            styles={{
                              ...selectStyles.select,
                              pointerEvents: 'none',
                              ...selectStyles['select > div'],
                              '& > div': {
                                pointerEvents: 'auto'
                              },
                              position: 'absolute',
                              ...(errors?.[type]?.message
                                ? {
                                    ...multiMedia({
                                      margin: '0 0 48px 16px',
                                      tablet: { margin: '0 0 46px 16px' }
                                    })
                                  }
                                : { margin: '0 0 16px 16px' })
                            }}>
                            <Div
                              styles={{
                                ...selectStyles.flag
                              }}
                              dangerouslySetInnerHTML={{
                                __html: (flag as Record<string, any>)[
                                  countryCode || 'RU'
                                ]
                              }}
                            />

                            <Div
                              className='flags-scroll'
                              styles={{
                                ...selectStyles.flagsScroll,
                                boxSizing: 'border-box',
                                display: isOpen ? 'flex' : 'none',
                                flexDirection: 'column',
                                overflowY: 'scroll',
                                position: 'absolute'
                              }}>
                              {Object.entries(
                                contentPhonePrefixToCountryCode ?? {}
                              ).map((phoneCode, index) => (
                                <Div
                                  data-value={phoneCode[1].code}
                                  onClick={e => {
                                    const selectedCountry =
                                      e.currentTarget.getAttribute(
                                        'data-value'
                                      ) || 'RU'
                                    setCountryCode(selectedCountry)
                                    reset({
                                      phoneNumber:
                                        getPhonePrefixByCountryCode(
                                          selectedCountry
                                        )
                                    })
                                  }}
                                  styles={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    transition: 'background-color 0.3s',
                                    padding: '13px',
                                    color: 'black',
                                    width: '100%',
                                    ':hover': {
                                      backgroundColor: 'white'
                                    }
                                  }}
                                  key={`flag-option__${index + 1}`}>
                                  <Span
                                    styles={{
                                      ...multiMedia({
                                        display: 'block',
                                        tablet: { display: 'none' },
                                        phone: { display: 'none' }
                                      })
                                    }}>{`${phoneCode[1].ru} (${phoneCode[1].native})`}</Span>

                                  <Div
                                    styles={{
                                      display: 'flex',
                                      gap: '12px',
                                      minWidth: 'fit-content',
                                      // width: '100%',
                                      ...multiMedia({
                                        tablet: {
                                          width: '100%'
                                        },
                                        phone: {
                                          width: '100%'
                                        }
                                      }),
                                      justifyContent: 'space-between'
                                    }}>
                                    <Span>{phoneCode[0]}</Span>
                                    <FlagItem
                                      code={phoneCode[1].code}
                                      onClick={e => {
                                        const selectedCountry =
                                          e.currentTarget.getAttribute(
                                            'data-value'
                                          ) || 'RU'
                                        setCountryCode(selectedCountry)
                                        reset({
                                          phoneNumber:
                                            getPhonePrefixByCountryCode(
                                              selectedCountry
                                            )
                                        })
                                      }}
                                    />
                                  </Div>
                                </Div>
                              ))}
                            </Div>
                          </Select>
                        )}
                      </Layout.FlexItem>
                    )
                  )}
                <Layout.FlexItem
                  styles={styleVariants[styleVariant].buttonContainer}>
                  <Button
                    styles={styleVariants[styleVariant].button}
                    borderRadiusVariant='alpha'
                    type='submit'
                    disabled={isLoading}
                    {...colorsVariants[colorsVariant].button}>
                    {buttonValue || defaultButtonValue}
                  </Button>
                </Layout.FlexItem>
              </Layout.Flex>
            </Layout.FlexItem>

            {/* TODO: improve image UI */}
            {withImage && (isDesktop || isLaptop) && (
              <Layout.FlexItem
                styles={{
                  position: 'relative'
                }}>
                <Image
                  colorVariant='transparent'
                  src={image}
                  alt={imageAlt}
                  containerProps={{
                    styles: {
                      position: 'absolute',
                      top: '10px',
                      right: 0,
                      transform: 'scale(2) translateX(20%)'
                    }
                  }}
                />
              </Layout.FlexItem>
            )}
          </Layout.Flex>
          {(isLoading || isError || isSent) && !isJournal && (
            <P
              variant='kappa'
              styles={{
                // position: 'absolute',
                // top: '5rem',
                marginTop: '16px',
                marginBottom: 0,
                color:
                  (isError && 'var(--zeta)') ||
                  (isSent && 'var(--epsilon)') ||
                  'var(--omega)'
              }}>
              {isLoading && isLoadingText}
              {isError && isErrorText}
              {isSent && isSentText}
            </P>
          )}
          {(isPsychology || !isLabel) && (
            <Span style={styleVariants[styleVariant].policyAgreement}>
              {mdToJsx(insertWhiteSpacesAfterPrepositions(policyAgreement), {
                replace: ['p']
              })}
            </Span>
          )}
        </Form>
        {isPopup && !isJournal && (
          <Div styles={styleVariants[styleVariant].ballsContainer}>
            <FormBalls.LeftTop />
            <FormBalls.RightTop />
            <FormBalls.RightBottom />
            <FormBalls.LeftBottom />
          </Div>
        )}
      </Div>
      {/* Flocktory banner */}
      {isSent && (
        <div
          className='i-flocktory'
          data-fl-action='exchange'
          data-fl-spot='leadForm_spot'
          data-fl-user-email={watchedEmail || 'xname@flocktory.com'}
          {...(watchedName && { 'data-fl-user-name': watchedName })}
        />
      )}
    </>
  )
}

export default LeadForm
