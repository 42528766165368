import Popup from 'reactjs-popup'
import { MouseEventHandler, useState } from 'react'
import {
  Button,
  Container,
  Div,
  FormBalls,
  H2,
  IconPlus,
  multiMedia,
  toUnit,
  useMedia,
  useTranslations
} from 'fractals'
// import { Shared } from 'modules'
import dynamic from 'next/dynamic'
import { TLeadPopupProps } from './types'
import { HEADING_DEFAULT } from './constants'
import { TLeadFormProps } from '../LeadForm/types'

const LeadForm = dynamic<TLeadFormProps>(
  () => import('modules/shared/widgets/LeadForm/LeadForm'),
  { ssr: false }
)

// TODO: add form props
export const LeadPopup = ({
  cost,
  buttonProps,
  temporalFortuneWheel = null,
  ...props
}: TLeadPopupProps) => {
  const { isTablet, isLaptop, isDesktop } = useMedia()
  const [load, setLoad] = useState(false)

  const [headingDefault] = useTranslations(HEADING_DEFAULT)

  return (
    <Popup
      trigger={<Button {...buttonProps} />}
      modal
      nested
      closeOnDocumentClick
      closeOnEscape
      lockScroll
      onOpen={() => setLoad(true)}
      {...props}>
      {/* @ts-expect-error TODO: remove this line and fix the error */}
      {(close: MouseEventHandler) => (
        <Container>
          <Div
            styles={{
              position: 'relative',
              maxWidth: toUnit(
                ((isDesktop || isLaptop) && 910) ||
                  (isTablet && 479) ||
                  undefined
              ),
              borderRadius: toUnit(16),
              backgroundColor: 'var(--alpha)',
              color: 'var(--omega)'
            }}>
            <Button
              colorsVariant='transparent'
              sizeVariant='xs'
              onClick={close}
              styles={{
                position: 'absolute',
                top: 0,
                right: 0
              }}>
              <IconPlus colorVariant='omega' variant='cross' />
            </Button>
            <Div
              styles={{
                paddingTop: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 48) || 32
                ),
                paddingRight: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 32) || 16
                ),
                paddingBottom: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 48) || 32
                ),
                paddingLeft: toUnit(
                  ((isDesktop || isLaptop) && 64) || (isTablet && 32) || 16
                )
              }}>
              {load && (
                <LeadForm
                  styleVariant='alpha'
                  // id='ID HERE'
                  formName='FORM NAME HERE'
                  heading={<H2 variant='delta'>{headingDefault}</H2>}
                  colorsVariant='beta'
                  isSandwich
                  name
                  phoneNumber
                  cost={cost}
                  email
                  promocode
                  isPopup
                  styles={{
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0
                  }}
                  withImage
                  id='LeadPopup'
                  temporalFortuneWheel={temporalFortuneWheel}
                />
              )}
            </Div>
          </Div>
        </Container>
      )}
    </Popup>
  )
}

export default LeadPopup
